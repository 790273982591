import { CREDIT_CARD_DATA } from '../actions';

const INIT_STATE = {
  creditCartData: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CREDIT_CARD_DATA:
      return { ...state, creditCartData: action.payload };
    default:
      return { ...state };
  }
};
