export const loginUser = (user, history) => ({
  type: 'LOGIN_USER',
  payload: { user, history },
});
export const loginQrCode = (user, history) => ({
  type: 'LOGIN_QRCODE',
  payload: { user, history },
});
export const reconectUser = (user) => ({
  type: 'RECONECT_USER',
  payload: user,
});
export const loginUserSuccess = (payload) => ({
  type: 'LOGIN_USER_SUCCESS',
  payload,
});
export const setInterpreterIsConnected = (payload) => ({
  type: 'INTERPRETER_IS_CONNECTED',
  payload,
});
export const updateUser = (payload) => ({
  type: 'UPDATE_USER',
  payload,
});
export const loginUserError = () => ({
  type: 'LOGIN_USER_ERROR',
});

export const registerUser = (user, history) => ({
  type: 'REGISTER_USER',
  payload: { user, history },
});
export const registerUserSuccess = (user) => ({
  type: 'REGISTER_USER_SUCCESS',
  payload: user,
});
export const registerUserError = () => ({
  type: 'REGISTER_USER_ERROR',
});

export const logoutUser = (payload) => ({
  type: 'LOGOUT_USER',
  payload,
});
export const clearUser = () => ({
  type: 'CLEAR_USER',
});
