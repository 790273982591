import { NotificationManager } from '../components/common/react-notifications';
import { defaultDirection } from '../constants/defaultValues';
import { onlyNumbers } from './MaskInputs';

export const mapOrder = (array, order, key) => {
  array.sort((a, b) => {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}.${mm}.${yyyy}`;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDirection = () => {
  let direction = defaultDirection;
  if (localStorage.getItem('direction')) {
    const localValue = localStorage.getItem('direction');
    if (localValue === 'rtl' || localValue === 'ltr') {
      direction = localValue;
    }
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};

export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  localStorage.setItem('direction', direction);
};

export const validateEmail = (value) => {
  let error;
  if (!value) {
    error = 'E-mail é obrigatório';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = 'E-mail inválido';
  }
  return error;
};

export const validateCPF = (value) => {
  let error;
  if (!value) return (error = 'CPF é obrigatório');

  value = value.replace(/[^\d]+/g, '');

  if (value === '') return (error = 'CPF inválido');

  // Elimina values invalidos conhecidos
  if (
    value.length !== 11 ||
    value === '00000000000' ||
    value === '11111111111' ||
    value === '22222222222' ||
    value === '33333333333' ||
    value === '44444444444' ||
    value === '55555555555' ||
    value === '66666666666' ||
    value === '77777777777' ||
    value === '88888888888' ||
    value === '99999999999'
  )
    return (error = 'CPF inválido');

  // Valida 1o digito
  let add = 0;
  for (let i = 0; i < 9; i++) add += parseInt(value.charAt(i)) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(value.charAt(9))) return (error = 'CPF inválido');
  // Valida 2o digito
  add = 0;
  for (let i = 0; i < 10; i++) add += parseInt(value.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(value.charAt(10))) return (error = 'CPF inválido');
  return error;
};

export const validateCNPJ = (value) => {
  let error;
  value = value.replace(/[^\d]+/g, '');

  if (value === '') return (error = 'CNPJ é obrigatório');

  if (value.length !== 14) return (error = 'CNPJ inválido');

  // Elimina values invalidos conhecidos
  if (
    value === '00000000000000' ||
    value === '11111111111111' ||
    value === '22222222222222' ||
    value === '33333333333333' ||
    value === '44444444444444' ||
    value === '55555555555555' ||
    value === '66666666666666' ||
    value === '77777777777777' ||
    value === '88888888888888' ||
    value === '99999999999999'
  )
    return (error = 'CNPJ inválido');

  // Valida DVs
  let tamanho = value.length - 2;
  let numeros = value.substring(0, tamanho);
  const digitos = value.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== digitos.charAt(0)) return (error = 'CNPJ inválido');

  tamanho += 1;
  numeros = value.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== digitos.charAt(1)) return (error = 'CNPJ inválido');

  return error;
};

export const validateCNPJNotRequired = (value) => {
  let error;
  value = value.replace(/[^\d]+/g, '');

  if (value.length !== 14) return (error = 'CNPJ inválido');

  // Elimina values invalidos conhecidos
  if (
    value === '00000000000000' ||
    value === '11111111111111' ||
    value === '22222222222222' ||
    value === '33333333333333' ||
    value === '44444444444444' ||
    value === '55555555555555' ||
    value === '66666666666666' ||
    value === '77777777777777' ||
    value === '88888888888888' ||
    value === '99999999999999'
  )
    return (error = 'CNPJ inválido');

  // Valida DVs
  let tamanho = value.length - 2;
  let numeros = value.substring(0, tamanho);
  const digitos = value.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== digitos.charAt(0)) return (error = 'CNPJ inválido');

  tamanho += 1;
  numeros = value.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== digitos.charAt(1)) return (error = 'CNPJ inválido');

  return error;
};

export const validatePassword = (value) => {
  let error;
  if (!value) {
    error = 'Senha é obrigatória';
  } else if (value.length < 8) {
    error = 'Informe ao menos 8 caracteres';
  }
  return error;
};

export const toastSuccess = ({ message, time = 5000 }) => {
  NotificationManager.success('', message, time, null, null, '');
};

export const toastError = ({ message, time = 5000 }) => {
  NotificationManager.error('', message, time, null, null, '');
};

export const toastWarning = ({ message, time = 5000 }) => {
  NotificationManager.warning('', message, time, null, null, '');
};

export function returnBaseUrl() {
  if (window.location.href.includes('test'))
    return 'https://test.signumweb.com.br';
  if (window.location.href.includes('app'))
    return 'https://app.signumweb.com.br';
  return 'http://127.0.0.1:3000';
}

export function returnBaseUrlCallTwilio() {
  if (window.location.href.includes('test'))
    return 'https://salatest.signumweb.com.br';
  if (window.location.href.includes('app'))
    return 'https://sala.signumweb.com.br';
  return 'http://localhost:3001';
}

export function returnBaseUrlBackend() {
  if (window.location.href.includes('app'))
    return 'https://api.signumweb.com.br';
  // return 'http://172.31.29.188:80';
  if (window.location.href.includes('test'))
    return 'https://apitest.signumweb.com.br';
  // return 'https://172.31.7.110:443';
  // return 'http://192.168.8.224:3333';
  return 'http://127.0.0.1:3333';
}

export function returnBaseUrlWs() {
  if (window.location.href.includes('app')) return 'wss://api.signumweb.com.br';
  if (window.location.href.includes('test'))
    return 'wss://apitest.signumweb.com.br';
  // return 'ws://192.168.8.224:3333';
  return 'ws://127.0.0.1:3333';
}

export function openUrl(user, url = '', twilioRoon, replace) {
  if (url) {
    if (replace) {
      window.location.replace(url);
      return;
    }

    window.open(url.match(/^https?:/) ? url : `//${url}`, '_blank');
  } else if (twilioRoon) {
    const isInterpreter = user.user_type === 'Interpreter';
    const userName = `${user.name}-${Math.random().toString(36).substring(9)}`;
    const urlCallTwilio = returnBaseUrlCallTwilio();

    if (replace) {
      window.location.replace(
        `${urlCallTwilio}/room/${twilioRoon}?username=/${userName}`
      );
      return;
    }

    if (isInterpreter) {
      window.open(`${urlCallTwilio}/room/${twilioRoon}`, '_blank');
    } else {
      window.open(
        `${urlCallTwilio}/room/${twilioRoon}?username=${userName}`,
        '_blank'
      );
    }
  }
}

export function validatePhoneNumber(value) {
  const numbers = onlyNumbers(value);

  return /^\d{0,2}\s*\d{4,5}\d{3,4}$/.test(numbers);
}

export function validateUrl(value) {
  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    value
  );
}

export function handleFormatDate(date) {
  if (!date) return '';

  const partialDate = date.split('-');

  return `${partialDate[2]}/${partialDate[1]}/${partialDate[0]}`;
}

export function sendNotification(body) {
  if (!window.Notification || !Notification.requestPermission) {
    alert('Este navegador não suporta notificações de Desktop');
    return;
  }

  if (Notification.permission === 'granted') {
    try {
      const notification = new Notification('Signumweb', {
        icon: 'https://app.signumweb.com.br/assets/img/logo_signum_white.png',
        body,
      });
      notification.onclick = () => {};
    } catch (error) {
      console.log('notification error: ', error);
    }
  } else if (Notification.permission !== 'denied') {
    Notification.requestPermission((permission) => {
      if (permission === 'granted') {
        try {
          const notification = new Notification('Signumweb', {
            icon: 'https://app.signumweb.com.br/assets/img/logo_signum_white.png',
            body,
          });
          notification.onclick = () => {};
        } catch (error) {
          console.log('notification error: ', error);
        }
      }
    });
  }
}

export function getCurrentDate() {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export function addDaysToCurrentDate(days) {
  const date = new Date();
  return new Date(date.setDate(date.getDate() + days));
}

export function getCurrentDateFinal() {
  const date = new Date();
  date.setDate(date.getDate() + 30);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export function handleFormatDuration(number) {
  if (!number) return '00:00:00';

  let formatHours = Math.floor(number / 60);
  let formatMinutes = Math.floor(number % 60);

  formatHours = formatHours < 10 ? `0${formatHours}` : formatHours;
  formatMinutes = formatMinutes < 10 ? `0${formatMinutes}` : formatMinutes;

  return `${formatHours}:${formatMinutes}:00`;
}
