import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  CLEAR_USER,
  UPDATE_USER,
  INTERPRETER_IS_CONNECTED,
} from '../actions';

const INIT_STATE = {
  user: '',
  loading: false,
  interpreterIsConnected: false,
  ws: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true, error: '' };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.user,
        ws: action.payload.ws,
      };
    case UPDATE_USER:
      return {
        ...state,
        user: action.payload,
      };
    case LOGIN_USER_ERROR:
      return {
        ...state,
        loading: false,
        user: '',
      };
    case INTERPRETER_IS_CONNECTED:
      return {
        ...state,
        interpreterIsConnected: action.payload,
      };
    case REGISTER_USER:
      return { ...state, loading: true, error: '' };
    case REGISTER_USER_SUCCESS:
      return { ...state, loading: false, user: action.payload, error: '' };
    case REGISTER_USER_ERROR:
      return {
        ...state,
        loading: false,
        user: '',
      };
    case CLEAR_USER:
      return INIT_STATE;
    default:
      return { ...state };
  }
};
