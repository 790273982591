import {
  OPEN_MODAL_CALL,
  OPEN_MODAL_RATINGS,
  CHANNEL_CALL_WS,
  ATTENDANT_NAME,
  CALL_DATA,
  CALL_EXTERNAL,
  OPEN_MODAL_CALL_EXTERNAL
} from '../actions';

const INIT_STATE = {
  modalCall: false,
  openModalRatings: false,
  channelCallWs: {},
  attendantName: null,
  callData: {},
  callExternal: {},
  modalCallExternal: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case OPEN_MODAL_CALL:
      return { ...state, modalCall: action.payload };
    case OPEN_MODAL_RATINGS:
      return { ...state, openModalRatings: action.payload };
    case CHANNEL_CALL_WS:
      return { ...state, channelCallWs: action.payload };
    case ATTENDANT_NAME:
      return { ...state, attendantName: action.payload };
    case CALL_DATA:
      return { ...state, callData: action.payload };
    case CALL_EXTERNAL:
      return { ...state, callExternal: action.payload };
    case OPEN_MODAL_CALL_EXTERNAL:
      return { ...state, modalCallExternal: action.payload };  
    default:
      return { ...state };
  }
};
