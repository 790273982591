import { combineReducers } from 'redux';

import authUser from './auth/reducer';
import call from './call/reducer';
import chatApp from './chat/reducer';
import creditCard from './creditCard/reducer';
import listener from './listener/reducer';
import menu from './menu/reducer';
import settings from './settings/reducer';
import surveyDetailApp from './surveyDetail/reducer';
import surveyListApp from './surveyList/reducer';

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  call,
  creditCard,
  chatApp,
  surveyListApp,
  surveyDetailApp,
  listener,
});

export default reducers;
