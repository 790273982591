export const openModalCall = (payload) => ({
  type: 'OPEN_MODAL_CALL',
  payload,
});

export const openModalRatings = (payload) => ({
  type: 'OPEN_MODAL_RATINGS',
  payload,
});

export const setChannelCallWs = (payload) => ({
  type: 'CHANNEL_CALL_WS',
  payload,
});

export const attendantName = (payload) => ({
  type: 'ATTENDANT_NAME',
  payload,
});

export const callExternal = (payload) => ({
  type: 'CALL_EXTERNAL',
  payload,
});

export const openModalCallExternal = (payload) => ({
  type: 'OPEN_MODAL_CALL_EXTERNAL',
  payload,
});
